import React, { createContext, useState, useEffect, useContext } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isOfferingVisible, setIsOfferingVisible] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [acceptedOffer, setAcceptedOffer] = useState(false);
  const [declinedOffer, setDeclinedOffer] = useState(false);

  const showOfferingModal = () => setIsOfferingVisible(true);
  const hideOfferingModal = () => setIsOfferingVisible(false);
  const showDownloadModal = () => setIsDownloadModalVisible(true);
  const hideDownloadModal = () => setIsDownloadModalVisible(false);

  const declineOffer = () => {
    const now = new Date();
    localStorage.setItem(
      'modalClosed',
      JSON.stringify({ timestamp: now.getTime() })
    );
    setDeclinedOffer(true);
  }

  const acceptOffer = () => {
    const now = new Date();
    setAcceptedOffer(true);
    localStorage.setItem(
      'acceptedOffer',
      JSON.stringify({ timestamp: now.getTime() })
    )

    localStorage.removeItem('modalClosed')
  }

  useEffect(() => {
    console.log(isDownloadModalVisible);
    const acceptedOfferData = localStorage.getItem('acceptedOffer');
    const modalClosedData = localStorage.getItem('modalClosed');
  
    if (acceptedOfferData) {
      const { timestamp } = JSON.parse(acceptedOfferData);
      const oneYearAgo = new Date().getTime() - 31536000000;
      if (timestamp > oneYearAgo) {
        setAcceptedOffer(true);
      } else {
        localStorage.removeItem('acceptedOffer');
        setAcceptedOffer(false);
      }
    }
  
    if (!modalClosedData && !acceptedOfferData) {
      const timer = setTimeout(() => {
        showOfferingModal();
      }, 3000); // Delay modal appearance by 3000 milliseconds
      return () => clearTimeout(timer);
    } else if (modalClosedData) {
      const { timestamp } = JSON.parse(modalClosedData);
      const oneWeekAgo = new Date().getTime() - 604800000;
      if (timestamp <= oneWeekAgo) {
        localStorage.removeItem('modalClosed');
        const timer = setTimeout(() => {
          showOfferingModal();
        }, 3000); // Delay modal appearance by 3000 milliseconds
        return () => clearTimeout(timer);
      }
    }
  }, []);

  return (
    <ModalContext.Provider value={{ 
      isOfferingVisible,
      isDownloadModalVisible,
      showOfferingModal,
      hideOfferingModal,
      showDownloadModal,
      hideDownloadModal,
      acceptedOffer,
      setAcceptedOffer,
      acceptOffer,
      declinedOffer,
      setDeclinedOffer,
      declineOffer,
    }}>
      {children}
    </ModalContext.Provider>
  );
};

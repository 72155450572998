import React from 'react';
import { ModalProvider } from './src/contexts/ModalContext';
import { SearchProvider } from './src/contexts/SearchContext';
import { SubmissionProvider } from './src/contexts/SubmissionContext';

import './src/styles/global.scss';

export const wrapRootElement = ({ element }) => {
  return (
    <ModalProvider>
      <SubmissionProvider>
        <SearchProvider>{element}</SearchProvider>
      </SubmissionProvider>
    </ModalProvider>
  );
};

import React, { createContext, useState, useContext } from 'react';

const SubmissionContext = createContext();

export const useSubmission = () => useContext(SubmissionContext);

const initialFormStates = {
  offerForm: { fName: '', lName: '', email: '', location: 'Offer Form' },
  contactForm: { name: '', email: '', subject: '', message: '', subscribe: false, location: 'Contact Form' },
  waitlistForm: { name: '', email: '', location: 'Waitlist Form' },
};

export const SubmissionProvider = ({ children }) => {
  const [formData, setFormData] = useState(initialFormStates.offerForm); // Default to offer form
  const [message, setMessage] = useState('');

  const resetFormData = (formType) => {
    setFormData(initialFormStates[formType]);
  };

  const handleSubmit = async (data, handleAcceptOffer) => {
    console.log(data);
    handleAcceptOffer();

    try {
      const response = await fetch('/.netlify/functions/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.ok) {
        console.log('Success:', result.message);
        setMessage('Thank you for your submission!');
        resetFormData(data.location.toLowerCase().replace(' ', '') + 'Form');
      } else {
        console.error('Error:', result.message);
        setMessage('There was an error. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('There was an error. Please try again.');
    }
  };

  return (
    <SubmissionContext.Provider value={{ formData, setFormData, handleSubmit, resetFormData, message }}>
      {children}
    </SubmissionContext.Provider>
  );
};

